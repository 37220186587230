.staking {
  display: flex;
  flex-direction: column;
  align-items: start;
  width: 100%;
}

.staking__header {
  margin: 0;
  color: #3f8de5;
  font-family: Gilroy-Bold;
  font-size: 42px;
  font-weight: 400;
  line-height: normal;
}

.staking__subheader {
  margin: 5px 0 30px;
  color: #3a3a3a;
  font-family: Quicksand;
  font-size: 20px;
  font-weight: 400;
  line-height: normal;
}
.staking__subheaderss {
  margin: 15px 0 10px;
  color: #3a3a3a;
  font-family: Quicksand;
  font-size: 20px;
  font-weight: 600;
  line-height: normal;
}
.staking__stakes {
  display: flex;
  max-width: 1236px;
  justify-content: center;
  flex-wrap: wrap;
  margin: 0 auto;
  gap: 20px;
}

.stake {
  width: calc(294px - 42px);
  background-color: #fff;
  border-radius: 18px;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 18px 21px 40px;
  transition: 0.3s ease-in-out;
}

.stake:hover {
  transform: translateY(-15px);
}

.stake:hover .stake__plus {
  border: 1px solid #232323;
  background-color: #fff;
  color: #232323;
}

.stake__name {
  background-color: #f3f3f3;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 23px 0;
  border-radius: 16px;
}

.stake__subheader {
  margin: 0 0 9px;
  color: #000;
  font-family: Quicksand;
  font-size: 18px;
  font-weight: 700;
  line-height: normal;
}

.stake__header {
  margin: 0;
  color: #000;
  font-family: Quicksand;
  font-size: 18px;
  font-weight: 400;
  line-height: normal;
}

.stake__header_blue {
  color: #3f8de5;
  font-family: Gilroy-Bold;
  font-size: 20px;
  font-weight: 400;
  line-height: normal;
  text-transform: uppercase;
}

.stake__percents {
  margin: 17px 0;
  color: #000;
  font-family: Quicksand;
  font-size: 20px;
  font-weight: 500;
  line-height: normal;
}

.stake__percents_blue {
  color: #3f8de5;
  font-family: Quicksand;
  font-size: 42px;
  font-weight: 700;
  line-height: normal;
}

.stake__plus {
  padding: 9px 47px;
  border-radius: 31px;
  background-color: #232323;
  color: #fff;
  border: 1px solid transparent;
  text-align: center;
  font-family: Quicksand;
  font-size: 18px;
  font-weight: 400;
  line-height: normal;
  transition: 0.3s ease-in-out;
}

.stake__earn {
  margin: 18px 0px 23px;
  color: #000;
  font-family: Quicksand;
  font-size: 18px;
  font-weight: 400;
  line-height: normal;
}

.stake__earn_blue {
  color: #3f8de5;
  font-family: Quicksand;
  font-size: 25px;
  font-weight: 700;
  line-height: normal;
}

.stake__ul {
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0;
  gap: 11px;
}

.stake__li {
  margin: 0;
  display: flex;
  align-items: center;
  gap: 6px;
  color: #3a3a3a;
  font-family: Quicksand;
  font-size: 15px;
  font-weight: 400;
  line-height: 127.778%;
  list-style-type: none;
}

.stake__li::before {
  content: "";
  width: 9px;
  height: 9px;
  border-radius: 50%;
  background-color: #3f8de5;
}

@media screen and (max-width: 768px) {
  .main {
    padding: 40px 25px;
    max-width: calc(97.5vw - 50px);
  }
}
