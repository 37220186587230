@font-face {
    font-weight: 300;
    font-family: 'Quicksand';
    src: url(./Quicksand/Quicksand-Light.ttf);
}

@font-face {
    font-weight: 400;
    font-family: 'Quicksand';
    src: url(./Quicksand/Quicksand-Regular.ttf);
}

@font-face {
    font-weight: 500;
    font-family: 'Quicksand';
    src: url(./Quicksand/Quicksand-Medium.ttf);
}

@font-face {
    font-weight: 600;
    font-family: 'Quicksand';
    src: url(./Quicksand/Quicksand-SemiBold.ttf);
}

@font-face {
    font-weight: 700;
    font-family: 'Quicksand';
    src: url(./Quicksand/Quicksand-Bold.ttf);
}

@font-face {
    font-family: 'Gilroy-Bold';
    font-style: normal;
    font-weight: normal;
    src: local('Gilroy-Bold ☞'), url('./Gilroy-Bold/Gilroy-Bold.woff') format('woff');
}


@font-face {
    font-family: 'Gilroy-Heavy';
    font-style: normal;
    font-weight: normal;
    src: local('Gilroy-Heavy ☞'), url('./Gilroy-Bold/Gilroy-Heavy.woff') format('woff');
}


@font-face {
    font-family: 'Gilroy-Light';
    font-style: normal;
    font-weight: normal;
    src: local('Gilroy-Light ☞'), url('./Gilroy-Bold/Gilroy-Light.woff') format('woff');
}


@font-face {
    font-family: 'Gilroy-Medium';
    font-style: normal;
    font-weight: normal;
    src: local('Gilroy-Medium ☞'), url('./Gilroy-Bold/Gilroy-Medium.woff') format('woff');
}


@font-face {
    font-family: 'Gilroy-Regular';
    font-style: normal;
    font-weight: normal;
    src: local('Gilroy-Regular ☞'), url('./Gilroy-Bold/Gilroy-Regular.woff') format('woff');
}