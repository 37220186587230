.header {
  display: flex;
  width: 100vw;
  justify-content: center;
  margin: 40px 0 20px;
}

.header__container {
  width: 1240px;
  max-width: 95%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header__logo {
  background-image: url(../../source/images/3.gif);
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  width: 199px;
  height: 41px;
  transition: 0.3s ease-in-out;
}

.header__logo:hover {
  opacity: 0.7;
}

.header__link {
  padding: 16.5px 25px;
  background-color: #3f8de5;
  color: #fff;
  font-family: Quicksand;
  font-size: 18px;
  font-weight: 700;
  line-height: normal;
  border-radius: 33px;
  border: 1px solid #c1c1c1;
  transition: 0.3s ease-in-out;
}

.header__link__modals {
  width: 180px;
  height: 50px;
  background-color: #3f8de5;
  color: #fff;
  font-family: Quicksand;
  font-size: 18px;
  font-weight: 700;
  line-height: normal;
  border-radius: 33px;
  border: 1px solid #c1c1c1;
  transition: 0.3s ease-in-out;
}
.header__link:hover {
  background-color: #fff;
  color: #3f8de5;
}

@media screen and (max-width: 470px) {
  .header__container {
    flex-direction: column;
    justify-content: center;
    gap: 20px;
  }
}
