.main {
  padding: 20px 40px;
}

.staking__btns {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 24px;
}

.staking__nav {
  display: flex;
  gap: 15px;
}

.staking__btn {
  outline: none;
  border-radius: 33px;
  border: 1px solid #c1c1c1;
  background: #fff;
  color: #3a3a3a;
  font-family: Quicksand;
  font-size: 18px;
  font-weight: 400;
  line-height: normal;
  padding: 16.5px 25px;
  transition: 0.3s ease-in-out;
}

.staking__btn:hover {
  border: 1px solid #3a3a3a;
  background: #3a3a3a;
  color: #fff;
}

.staking__blocks {
  display: flex;
  width: 100%;
  gap: 20px;
}

.staking__pool {
  width: 100%;
  background: #fff;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap: 10px;
  border-radius: 18px;
}

.staking__pool__card {
  width: 100%;
  background: #fff;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  border-radius: 18px;
}
.staking__pool__card h4 {
  margin: 0;
  color: #000;
  font-family: Quicksand;
  font-size: 20px;
  font-weight: 400;
  line-height: normal;
}

.staking__pool.graph {
  width: 100%;
}

.staking__blocks.cards {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between !important;
  padding-bottom: 0 !important;
  align-items: center;
}

.staking__centralizer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.staking__blocks.cards {
  justify-content: space-between;
}

.staking__pool_full {
  width: 100%;
  margin: 15px 0;
}

.pool__subheader-cont {
  display: flex;
  justify-content: space-between;
}

.pool__subheader {
  margin: 0;
  color: #000;
  font-family: Quicksand;
  font-size: 20px;
  font-weight: 400;
  line-height: normal;
}

.pool__header-cont {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.pool__header-cont_margin {
  margin-bottom: 12px;
}

.pool__header {
  margin: 0;
  color: #3f8de5;
  font-family: Gilroy-Bold;
  font-size: 34px;
  font-weight: 400;
  line-height: normal;
  text-transform: uppercase;
}
.pool__headers {
  margin: 0;
  color: #3f8de5;
  font-family: Gilroy-Bold;
  font-size: 24px;
  font-weight: 400;
  line-height: normal;
  text-transform: uppercase;
}
.pool__header-span {
  color: #000;
  font-family: Quicksand;
  font-size: 20px;
  font-weight: 500;
  line-height: normal;
  text-transform: initial;
}

.pool__apr {
  margin: 0;
  color: #000;
  font-family: Quicksand;
  font-size: 20px;
  font-weight: 500;
  line-height: normal;
}

.pool__apr_blue {
  color: #3f8de5;
  font-size: 20px;
  font-weight: 700;
}

.pool__subheader_margin {
  margin-top: auto;
}

.pool__total {
  margin: 0;
  color: #3a3a3a;
  font-family: Quicksand;
  font-size: 18px;
  font-weight: 400;
  line-height: 127.778%;
}

.pool__remains {
  display: flex;
  justify-content: space-between;
  gap: 18px;
  margin-top: 14px;
}

.pool__select {
  position: relative;
  padding: 16.5px 34px 16.5px 25px;
  border-radius: 33px;
  border: 1px solid #c1c1c1;
  background: #3f8de5;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: #fff;
  transition: 0.3s ease-in-out;
}

.pool__select:hover {
  border: 1px solid #3f8de5;
  background: #fff;
  color: #3f8de5;
}

.pool__select:hover select {
  color: #3f8de5;
}

.pool__select:after {
  content: "▼";
  padding: 0 8px;
  font-size: 12px;
  position: absolute;
  right: 18px;
  top: 20.5px;
  z-index: 1;
  text-align: center;
  width: 10%;
  height: 100%;
  pointer-events: none;
  box-sizing: border-box;
}

.pool__select select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: transparent;
  border: 0;
  font-family: Quicksand;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  transition: 0.3s ease-in-out;
  line-height: normal;
}

.pool__select select:focus {
  border: 0;
  outline: none;
}

.pool__remain {
  width: 100%;
  border-radius: 52px;
  background: #eee;
  display: flex;
  align-items: start;
  position: relative;
}

.pool__remain-text {
  padding-left: 30px;
  z-index: 2;
  color: #000000;
  font-family: Quicksand;
  font-size: 18px;
  font-weight: 400;
  line-height: normal;
  padding-top: 15px;
}

.pool__remain-text_underline {
  text-decoration: underline;
}

.pool__remain-line {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: #3f8de5;
  z-index: 1;
  border-radius: 52px;
}

.graph__container {
  display: flex;
  justify-content: space-between;
}

.graph__cont {
  display: flex;
  flex-direction: column;
}

.graph__text {
  font-size: 17px;
}

.graph__text:first-of-type {
  margin-top: 13px;
}

.graph__select {
  align-self: start;
  color: #3a3a3a;
  background-color: #f4f4f4;
  max-width: 100%;
}

.graph__select select {
  color: #3a3a3a;
}

.graph__img {
  width: 100%;
  margin-top: auto;
}

.staking__pool__flexer {
  display: flex;
  justify-content: space-between;
  width: 100%;
  gap: 1rem;
}

.staking__pool__flexer.single__column {
  flex-direction: column;
}

.staking__blocks.tokens {
  flex-wrap: wrap;
  align-items: start;
  align-self: start;
  justify-content: start;
  gap: 1rem;
}

.token {
  /* width: 49%; */
  flex-direction: column;
}

.token_height {
  padding: 25px;
  max-width: calc(100% - 50px);
  width: 100%;
}

.token__header_blue {
  font-family: Quicksand;
  font-size: 24px;
  font-weight: 700;
}

.pool__subheader_margin_top {
  margin-top: 34px;
}

.pool__subheader_large {
  width: 100%;
  margin: 31px 0 16px;
}

.compound {
  margin-top: 24px;
}

.graph {
  width: calc(49% - 54px);
}

@media screen and (max-width: 1124px) {
  .main {
    padding: 50px 25px;
    max-width: calc(95vw - 50px);
  }

  .graph__container {
    flex-direction: column;
    gap: 10px;
  }

  .token {
    flex-direction: column;
    align-items: start;
    justify-content: start;
    padding: 26px 27px;
    width: 100%;
  }

  .tokens {
    gap: 20px;
  }

  .pool__subheader_large {
    margin: 0;
  }
}

@media screen and (max-width: 905px) {
  .main {
    padding: 40px 25px;
    max-width: calc(97.5vw - 50px);
  }

  .staking__blocks {
    flex-direction: column;
    gap: 20px;
  }

  .staking__pool {
    width: 100%;
  }

  .tokens {
    width: 100%;
    flex-direction: row;
    gap: 2%;
  }

  .pool__subheader_large {
    margin: 31px 0 16px;
  }

  .token {
    width: 100%;
  }

  .pool__remain-line {
    min-width: 100%;
  }
}

@media screen and (max-width: 535px) {
  .pool__subheader-cont {
    flex-direction: column;
  }

  .pool__header-cont {
    flex-direction: column;
    align-items: start;
    justify-content: start;
    margin: 15px 0;
  }

  .pool__remains {
    flex-direction: column;
  }

  .graph {
    gap: 40px;
  }

  .staking__btns {
    flex-direction: column;
    gap: 1rem;
    justify-content: center;
  }

  .staking__nav {
    flex-direction: column;
  }

  .pool__remain-text {
    font-size: 16px;
  }

  .tokens {
    width: 100%;
    flex-direction: column;
    gap: 20px;
  }

  .pool__subheader_large {
    margin: 0;
  }

  .token {
    width: 100%;
  }

  .staking__blocks.cards .staking__pool__flexer {
    flex-direction: column;
  }
}
